<template>
<div>
     <vs-alert color="danger" class="mb-4" title="Your email address is not verified! Verify to be notified when your order is delivered" >
       <div class="py-3">
            <span class="mr-4">Kindly check your inbox to verify. If in anyway you didn't receive email, recheck your registered email and </span>
            <a @click.prevent="resentLink()" class="" target="_blank"  href="#">Click to Resend Link Now!</a>
        </div>
    </vs-alert>
</div>
</template>

<script>
export default {
    methods:{
        resentLink(){
            this.$vs.loading({ color: "#444", type: "sound"})
            this.$http.get(`/account/resend-link`)
            .then((response) => {
                this.$vs.loading.close()
                if(response.data.success){
                    this.$vs.dialog({
                        color: 'primary',
                        title: 'A link has been sent to your email!',
                        text: `${response.data.data.message}`,
                        acceptText: "Okay"
                    })
                }
            })
        }
    }
}
</script>
