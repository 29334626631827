
<template>
  <div>
     <user-email-verification v-if="!activeUser.is_verified" />

    <div class="vx-row">
      <div class="vx-col w-1/2 md:w-1/2 lg:w-1/4 mb-base">
        <statistics-card-line
          icon="ShoppingCartIcon"
          :hideChart="true"
          :statistic="result.orders.draft | k_formatter"
          statisticTitle="Draft Orders"
          color='danger'
          type='area' />
      </div>
      <div class="vx-col w-1/2 md:w-1/2 lg:w-1/4 mb-base">
        <statistics-card-line
          icon="CoffeeIcon"
          :hideChart="true"
          :statistic="result.orders.pending | k_formatter"
          statisticTitle="Pending Orders"
          color='success'
          type='area' />
      </div>

      <div class="vx-col w-1/2 md:w-1/2 lg:w-1/4 mb-base">
        <statistics-card-line
          icon="CompassIcon"
          :hideChart="true"
          :statistic="result.orders.delivered"
          statisticTitle="Delivered Orders"
          color='warning'
          type='area' />
      </div>
      <div class="vx-col w-1/2 md:w-1/2 lg:w-1/4 mb-base">
        <statistics-card-line
          icon="AwardIcon"
          :hideChart="true"
          :statistic="result.orders.completed | k_formatter"
          statisticTitle="Completed Orders"
          color='primary'
          type='area' />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <order-list-table />
      </div>
    </div>
  </div>
</template>

<script>

import OrderListTable from '@/components/order/OrderListTable.vue'
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import UserEmailVerification    from "@/components/UserEmailVerification.vue"

export default{
    components: {
      StatisticsCardLine,
      UserEmailVerification,
      OrderListTable,
    },
    data() {
      return {
        result:{
          orders: {
            draft: 0,
            pending: 0,
            delivered: 0,
            completed: 0
          }
        }
      }
    },
    computed:{
        activeUser(){
            return this.$store.state.AppActiveUser
        }
    },
    created() {
     this.$http.get(`/analytics`)
      .then((response) => {
        if(response.data.success){
          this.result = response.data.data
        }
      })
    }
}
</script>

<style lang="scss">
.chat-card-log {
    height: 400px;

    .chat-sent-msg {
        background-color: #f2f4f7 !important;
    }
}

.vx-card.system-user-chart .vx-card__body{
  padding-bottom:5px;
}
</style>
